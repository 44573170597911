<template>
  <div class="collection-form white pa-5 py-3">
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <v-form
    class="mt-5"
      v-model="valid"
      @submit.prevent="submitHandler"
      ref="formAttraction"
    >
      <v-row>
        <!-- image uploader -->
        <v-col cols="12" class="py-1 pb-2">
          <div class="upload-image">
            <img
              v-if="
                newCoverImage
                  ? newCoverImage
                  : obj.coverImage
                  ? obj.coverImage.url
                  : false
              "
              :src="
                newCoverImage
                  ? newCoverImage
                  : obj.coverImage
                  ? obj.coverImage.url
                  : ''
              "
              alt=""
            />
            <div class="upload-btn cursor-pointer">
              <v-icon dark color="grey">mdi-upload</v-icon>
              <span class="font-15 grey--text">Upload Image</span>
              <v-file-input
                @change="handleCoverImage"
                accept="image/*"
                label="File input"
                class="file-upload"
                hide-details="true"
              ></v-file-input>
            </div>
          </div>
        </v-col>
        <!--NAME  -->
        <v-col cols="12" md="6" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> NAME </label>
          <base-input
            placeholder="Name"
            v-model="obj.name[language]"
            :rules="[(v) => !!v || '']"
          >
          </base-input>
        </v-col>

        <!-- LOCATION -->
        <v-col cols="12" md="6" class="py-1">
          <label class="grey--text font-12 font-500"> City Name </label>
          <GmapAutocomplete
            class="place-input"
            ref="gMapAutocomplete"
            :select-first-on-enter="true"
            placeholder="City name"
            @place_changed="setPlace"
          />
        </v-col>

        <!-- MAP -->
        <v-col cols="12" md="12" class="py-1">
          <GmapMap
            :clickable="true"
            :center="center"
            :zoom="zoom"
            map-type-id="roadmap"
            style="width: 100%; height: 500px"
            @click="addMarker"
          >
            <GmapMarker
              v-if="this.marker"
              :position="{ lat: this.marker.lat, lng: this.marker.lng }"
            />
          </GmapMap>
        </v-col>

        <!--VILLAGE  -->
        <v-col cols="12" md="6" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> VILLAGE </label>
          <auto-complete
            :rules="[(v) => !!v || 'this field is require']"
            v-model="obj.village"
            :items="villages"
            itemText="name"
            itemValue="_id"
            placeholder="Village"
          >
          </auto-complete>
        </v-col>

        <!-- SEASON -->
        <v-col cols="12" md="6" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> SEASON </label>
          <base-select
            :rules="[(v) => !!v || 'this field is require']"
            v-model="obj.season"
            :items="seasons"
            placeholder="Season"
            itemText="name"
            itemValue="_id"
          ></base-select>
        </v-col>

        <!--CATEGORY  -->
        <v-col cols="12" md="6" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> CATEGORY </label>
          <base-select
            :rules="[(v) => !!v || 'this field is require']"
            v-model="obj.category"
            @input="cagetoryHandler"
            :items="categories"
            itemText="name"
            itemValue="_id"
            placeholder="Category"
          >
          </base-select>
        </v-col>

        <!-- Filters  -->
        <v-col cols="12" v-if="filters.length > 0" class="py-1 pb-2">
          <span class="font-500 font-14 d-block">Filters: </span>
          <v-row class="mt-2">
            <v-col
              cols="12"
              md="3"
              class="py-0"
              v-for="(filter, i) in filters"
              :key="i"
            >
              <v-checkbox
                class="font-12"
                hide-details="true"
                v-model="filter.checked"
                :label="filter.filter.name"
              ></v-checkbox>

              <div v-if="filter.checked">
                <base-select
                  :items="filter.filter.props"
                  itemText="name"
                  :multiple="filter.filter.inputType == '1' ? true : false"
                  itemValue="_id"
                  v-model="filter.filter.value"
                  placeholder="select stat"
                ></base-select>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <!-- WEBSITE -->
        <v-col cols="12" md="6" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> WEBSITE </label>
          <base-input
            placeholder="Website"
            v-model="obj.contact.website"
            :rules="[]"
          >
          </base-input>
        </v-col>

        <!-- PHONE NUMBER -->
        <v-col cols="12" md="6" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> PHONE NUMBER </label>
          <base-input
            placeholder="Phone Number"
            v-model="obj.contact.phone"
            :rules="[]"
          >
          </base-input>
        </v-col>

        <!-- EMAIL -->
        <v-col cols="12" md="6" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> EMAIL </label>
          <base-input
            placeholder="Email"
            v-model="obj.contact.email"
            :rules="[]"
          >
          </base-input>
        </v-col>

        <!-- INSTAGRAM -->
        <v-col cols="12" md="6" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> INSTAGRAM </label>
          <base-input
            placeholder="Instagram"
            v-model="obj.social.instagram"
            :rules="[]"
          >
          </base-input>
        </v-col>

        <!-- FACEBOOK -->
        <v-col cols="12" md="6" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> FACEBOOK </label>
          <base-input
            placeholder="Facebook"
            v-model="obj.social.facebook"
            :rules="[]"
          >
          </base-input>
        </v-col>

        <!-- TWITTER -->
        <v-col cols="12" md="6" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> TWITTER </label>
          <base-input
            placeholder="Twitter"
            v-model="obj.social.twitter"
            :rules="[]"
          >
          </base-input>
        </v-col>

        <!-- DISTRICT -->
        <v-col cols="12" md="6" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> DISTRICT </label>
          <base-select
            :rules="[(v) => !!v || 'this field is require']"
            v-model="obj.location.district"
            :items="districts"
            placeholder="District"
            itemText="name"
            itemValue="_id"
          ></base-select>
        </v-col>

        <!-- TITLE -->
        <v-col cols="12" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> HEADER </label>
          <base-textarea
            :rows="3"
            placeholder="header"
            v-model="obj.header[language]"
            :rules="[]"
          >
          </base-textarea>
        </v-col>
        <!-- BODY -->
        <v-col cols="12" class="py-1 pb-2">
          <label class="grey--text font-12 font-500"> BODY </label>
          <base-textarea
            :rows="3"
            placeholder="body"
            v-model="obj.body[language]"
            :rules="[]"
          >
          </base-textarea>
        </v-col>
        <!-- upload image -->
        <!-- <v-col cols="12" class="py-1 pb-2"></v-col> -->
      </v-row>
      <v-divider class="divider-dark mb-3 mt-8"></v-divider>

      <!-- images -->
      <div class="collection-form-step-content py-9">
        <span class="black--text font-16 font-500 d-block mb-3"
          >Attraction Images</span
        >
        <div :key="filesUploaded">
          <div v-for="(file, i) in obj.media" :key="i">
            <div
              class="upload-item d-flex align-center justify-space-between px-6 py-3 mb-2 whitebg"
            >
              <div
                style="z-index: 99; position: relative"
                @click="previewHandler(file, i)"
              >
                <v-icon color="grayicon">mdi-folder-multiple-image</v-icon>
                <span class="font-14 black--text d-inline-block ml-2">{{
                  file.fileName || file.name
                }}</span>
              </div>
              <v-icon
                class="upload-item-delete"
                size="16"
                @click="deleteFile(i)"
                >mdi-close</v-icon
              >
            </div>
          </div>
        </div>

        <!-- upload new -->
        <div class="upload-item px-6 py-3">
          <div>
            <v-icon color="grayicon">mdi-folder-multiple-image</v-icon>
            <span class="font-14 black--text d-inline-block ml-2"
              >Upload Image</span
            >
          </div>
          <v-file-input
            prepend-icon=""
            @change="handleUpload($event, i)"
            height="50"
            hide-details="true"
            accept="image/*"
            multiple
          ></v-file-input>
        </div>
      </div>
      <v-row justify="center">
        <v-col cols="12" class="mt-14">
            <!-- buttons -->
            <buttons-experience
            @cancel="$emit('close')"
            @next="submitHandler"
            text="Submit"
            :isValid="valid"
            :key="valid"
            ></buttons-experience>
          </v-col>
      </v-row>
    </v-form>

    <v-dialog
      v-model="imagePreview"
      max-width="600"
      content-class="pa-10 no-shadow"
    >
      <div class="preview-dialog">
        <v-icon
          @click="imagePreview = false"
          class="close-preview cursor-pointer"
          color="primary"
          >mdi-close</v-icon
        >

        <img
          width="100%"
          height="400px"
          class="object-cover"
          :src="imagePreviewUrl"
          alt=""
        />
      </div>
    </v-dialog>
  </div>
</template>
<script>
import AutoComplete from "@/components/base/AutoComplete.vue";
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  components: { AutoComplete, ButtonsExperience },
  props: {
    paramID: {
      type: String,
      default: undefined
    }
  },
  data: () => ({
    valid: false,
    categories: [],
    districts: [],
    villages: [],
    seasons: [
      { name: "All", _id: "all" },
      { name: "Summer", _id: "summer" },
      { name: "Winter", _id: "winter" }
    ],
    countries: [{ name: "Lebanon", _id: "LB" }],

    obj: {
      name: {},
      contact: {},
      social: {},
      sections: { restaurants: false },
      filters: [],
      media: [],
      location: {},
      options: [],
      header: {},
      body: {}
    },
    filters: [],
    imagePreview: false,
    imagePreviewUrl: "",
    filesUploaded: false,

    selectedTab: 0,
    includes: [{ description: "", included: true, price: null }],
    extras: [{ description: "", included: false, price: 0 }],
    newCoverImage: "",

    loadingRequest: false,

    marker: undefined,
    center: { lng: 35.8984486, lat: 33.8786494 },
    zoom: 9.5,

    language: "en"
  }),
  methods: {
    handleCoverImage(file) {
      this.newCoverImage = URL.createObjectURL(file);
      this.obj.coverImage = file;
    },
    async cagetoryHandler(cat) {
      let id;
      if (cat._id) {
        id = cat._id;
      } else {
        id = cat;
      }
      let { data } = await this.$axios.get(`filters/category/${id}`);
      if (data) {
        if (this.paramID) {
          let arr = [];
          this.filters = data.map((filter) => {
            arr = [];
            this.obj.filters.map((s) => {
              filter.props.forEach((f) => {
                if (s == f._id) {
                  arr.push(f._id);
                }
              });
              return [...arr];
            });
            filter.value = arr;
            if (arr.length > 0 && filter.inputType == "1") {
              return { filter, checked: true };
            }
            if (arr.length > 0 && filter.inputType == "0") {
              filter.value = arr[0];
              return { filter, checked: true };
            } else {
              return { filter, checked: false };
            }
          });
        } else {
          this.filters = data.map((filter) => {
            return { filter, checked: false };
          });
        }
      }
    },
    setPlace(event) {
      if (event.geometry && event.geometry.location) {
        this.marker = {
          lat: event.geometry.location.lat(),
          lng: event.geometry.location.lng()
        };
        this.center = event.geometry.location;
        this.zoom = 15;
        this.obj.location.long = this.marker.lng;
        this.obj.location.lat = this.marker.lat;
        this.obj.location.address = this.$refs.gMapAutocomplete.$el.value;
      }
    },
    addMarker(event) {
      this.marker = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      this.obj.location.long = this.marker.lng;
      this.obj.location.lat = this.marker.lat;
      this.obj.location.address = this.$refs.gMapAutocomplete.$el.value;
    },
    // images
    deleteFile(i) {
      this.obj.media = [...this.obj.media.filter((file, idx) => idx != i)];
      this.filesUploaded = !this.filesUploaded;
    },
    handleUpload(files) {
      files.forEach((file) => {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
          this.$store.dispatch("showSnack", {
            text: "File size too big! bigger than 1MB, select a smaller one",
            color: "error"
          });
          return;
        }
        this.obj.media.push(file);
      });
      this.filesUploaded = !this.filesUploaded;
    },
    // preview image
    previewHandler(file) {
      if (file._id) {
        this.imagePreview = true;
        this.imagePreviewUrl = file.url;
        return;
      }
      this.imagePreview = true;
      this.imagePreviewUrl = URL.createObjectURL(file);
    },

    // options
    addNewOptionHandler(type) {
      if (type == "includes") {
        this[type].push({ description: "", included: true, price: null });
      } else {
        this[type].push({ description: "", included: false, price: "" });
      }
    },
    removeOptionHandler(type, i) {
      this[type] = [...this[type].filter((_, idx) => idx != i)];
    },
    async submitHandler() {
      this.$refs.formAttraction.validate();
      if (!this.valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth"
            });
        }, 100);
        return;
      }

      this.obj.options = [...this.includes, ...this.extras];
      // add function
      this.loadingRequest = true;
      if (this.filters.length > 0) {
        // get only checked stats
        this.filters = [...this.filters.filter((stat) => stat.checked)];
        this.obj.filters = [...this.filters.map((stat) => stat.filter.value)];
        this.obj.filters = [].concat.apply([], this.obj.filters);
      }

      // coordinates
      if (!this.obj.location.long || !this.obj.location.lat) {
        this.$store.dispatch("showSnack", {
          text: "Location pin is missing, please use the map to mark it.",
          color: "error"
        });
        this.loadingRequest = false;
        return;
      }
      // images
      if (this.obj.media.length == 0) {
        this.$store.dispatch("showSnack", {
          text: "one image require at least",
          color: `error`
        });
        this.loadingRequest = false;
        return;
      }

      if (!this.paramID) {
        // coverImage
        if (!this.newCoverImage) {
          this.$store.dispatch("showSnack", {
            text: "image cover required",
            color: `error`
          });
          this.loadingRequest = false;
          return;
        }

        let coverImage = this.obj.coverImage;
        let media = this.obj.media;

        let reqObj = this.obj;

        delete reqObj["media"];
        delete reqObj["coverImage"];
        // create miniguide id
        let resCreate = await this.$axios.post(`miniguide/add/new`, reqObj);

        if (resCreate.data) {
          let formDataCover = new FormData();
          formDataCover.append("miniguideId", resCreate.data.data._id);
          formDataCover.append("coverImage", coverImage);
          let resAddCover = await this.$axios.post(
            `media/miniguide/cover`,
            formDataCover
          );
          if (resAddCover.data.data) {
            // request to upload media
            media.forEach(async (img, idx) => {
              let formDataMedia = new FormData();
              formDataMedia.append("miniguideId", resCreate.data.data._id);
              formDataMedia.append("media", img);
              let resMedia = await this.$axios.post(
                `media/miniguide`,
                formDataMedia,
                {
                  "content-type": "multipart/form-data"
                }
              );
              if (resMedia.data) {
                if (idx == media.length - 1) {
                  this.$store.dispatch("showSnack", {
                    text: "Added succesfully",
                    color: `success`
                  });
                }
              }
            });
          }
          this.loadingRequest = false;
          this.$emit("close");
          this.$emit("reloadData");
        } else {
          this.$store.dispatch("showSnack", {
            text: resCreate.message,
            color: `error`
          });
          this.loadingRequest = false;
          return;
        }
      }
      // edit function
      else {
        // coverImage
        if (!this.newCoverImage && !this.obj.coverImage._id) {
          this.$store.dispatch("showSnack", {
            text: "image cover required",
            color: `error`
          });
          this.loadingRequest = false;
          return;
        }

        let coverImage = this.obj.coverImage;
        let media = this.obj.media.filter((img) => !img._id);

        let reqObj = this.obj;
        if (!coverImage._id) {
          delete reqObj["coverImage"];
        } else {
          this.obj.coverImage = this.obj.coverImage._id;
        }

        this.obj.media = this.obj.media.filter((img) => img._id);

        // delete reqObj["media"];
        // create miniguide id
        let resEdit = await this.$axios.put(
          `miniguide/edit/new/${this.paramID}`,
          reqObj
        );
        if (resEdit.data) {
          if (!coverImage._id) {
            let formDataCover = new FormData();
            formDataCover.append("miniguideId", this.paramID);
            formDataCover.append("coverImage", coverImage);
            let resCover = await this.$axios.post(
              `media/miniguide/cover`,
              formDataCover
            );
            if (resCover.data && media.length == 0) {
              this.$store.dispatch("showSnack", {
                text: "Edited succesfully",
                color: `success`
              });
            }
          } else {
            if (media.length == 0) {
              this.$store.dispatch("showSnack", {
                text: "Edited succesfully",
                color: `success`
              });
            }
          }

          // request to upload media
          media.forEach(async (img, idx) => {
            let formDataMedia = new FormData();
            formDataMedia.append("miniguideId", this.paramID);
            formDataMedia.append("media", img);
            let resMedia = await this.$axios.post(
              `media/miniguide`,
              formDataMedia,
              {
                "content-type": "multipart/form-data"
              }
            );
            if (resMedia.data) {
              if (idx == media.length - 1) {
                this.$store.dispatch("showSnack", {
                  text: "Edited succesfully",
                  color: `success`
                });
              }
            }
          });
          this.loadingRequest = false;
          this.$emit("close");
          this.$emit("reloadData");
        } else {
          this.$store.dispatch("showSnack", {
            text: resEdit.message,
            color: `error`
          });
          this.loadingRequest = false;
          return;
        }
      }
    },
    async fetchSelectors() {
      let { data } = await this.$axios.get("activity/all/selectors");
      if (data) {
        this.districts = data.districts;
        this.villages = data.villages;
      }
    },
    async fetchCategories() {
      let { data } = await this.$axios.get(`miniguide/category/dropdown`);
      if (data) {
        this.categories = data.data;
      }
    }
  },
  async created() {
    this.language = this.$root.language;
    this.obj.country = this.$root.country.short;
    await this.fetchSelectors();
    await this.fetchCategories();
    if (this.paramID) {
      let { data } = await this.$axios.get(`/miniguide/${this.paramID}`);
      if (data) {
        if (!data.sections) {
          data.sections = { restaurants: false };
        }
        this.obj = data;
        this.obj.social = this.obj.social || {};
        this.marker = {
          lng: Number(this.obj.location.long),
          lat: Number(this.obj.location.lat)
        };
        this.center = this.marker;
        this.zoom = 15;
        this.$refs.gMapAutocomplete.$el.value = this.obj.location.address;

        if (data.category.name) {
          await this.cagetoryHandler(data.category._id);
        }
        if (data.options.length > 0) {
          this.includes = [...data.options.filter((option) => option.included)];
          this.extras = [...data.options.filter((option) => !option.included)];
        }
      }
    }
  }
};
</script>

<style lang="scss">
.place-input {
  height: 36px;
  line-height: 35px;
  border: 1px solid #e2e5ed;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}
.new-miniguide {
  .upload-image {
    width: 100%;
    height: 250px;
    border: 1px solid #e2e5ed;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .upload-btn {
      position: absolute;
      bottom: 20px;
      left: 20px;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.5);
      padding: 5px 10px;

      .file-upload {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 9;
        margin: 0px !important;
        padding: 0px !important;

        label {
          display: none !important;
        }
      }
    }
  }
  .form-tabs {
    border-bottom: 1px solid rgba(#cecfd2, 0.8);
  }
}
</style>
