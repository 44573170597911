<template>
  <div class="base-input input-style base-select">
    <v-autocomplete
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :items="items"
      dense
      outlined
      chips
      :value="value"
      :rules="rules"
      :item-value="itemValue"
      :item-text="itemText"
      :hide-details="hideDetails"
      small-chips
      :placeholder="placeholder"
      color="darkblue"
      return-object
      menu-props="auto"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "_id",
    },
    itemText: {
      type: String,
      default: "name",
    },
    value: {
      validator: () => true,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || ""],
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    slotOptions: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss"></style>
