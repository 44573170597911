<template>
  <div class="attractions-body py-5" v-if="itemID">
    <div class="pb-2 d-flex" v-if="!viewOnly">
      <v-icon
        size="40"
        class="cursor-pointer ml-auto"
        @click="collapse"
        color="black"
        >mdi-progress-close</v-icon
      >
    </div>
    <div class="attractions-body-content">
      <div class="main-item pt-3" v-if="!viewOnly">
        <div class="main-img">
          <img
            v-if="itemID.coverImage"
            width="100%"
            height="100%"
            class="object-cover"
            :src="itemID.coverImage.url"
            alt=""
          />
        </div>
        <div class="b-bottom pt-4">
          <!-- text details -->
          <div class="main-title d-flex align-center justify-space-between">
            <div>
              <span
                class="main-title d-block my-2 black--text transition-3s font-20 font-500"
                >{{ itemID.name[$root.language] }}</span
              >
              <span
                class="black-grey--text font-14"
                v-if="itemID.location && itemID.location.district"
                >{{ itemID.location.address }},
                {{ itemID.location.district.name[$root.language] }} -
                {{ itemID.location.district.country }}</span
              >
            </div>
            <div class="d-block" v-if="!viewOnly">
              <div
                v-if="actions.includes('delete')"
                class="icon-cover mr-1 cursor-pointer base-btn"
                @click="deleteConfirm"
              >
                <v-icon color="error">mdi-delete</v-icon>
              </div>
              <div
                v-if="actions.includes('editCompanion')"
                class="icon-cover mr-1 cursor-pointer base-btn"
                @click="editCompanionValues"
              >
                <v-icon color="black-grey">mdi-percent-outline</v-icon>
              </div>
              <div
                v-if="actions.includes('edit')"
                @click="editAttraction"
                class="icon-cover mr-1 cursor-pointer base-btn"
              >
                <v-icon color="black-grey">mdi-pencil</v-icon>
              </div>
              <div
                v-if="actions.includes('restore')"
                class="icon-cover mr-1 cursor-pointer base-btn"
                @click="$emit('restoreHandler', itemID)"
              >
                <v-icon color="green">mdi-file-undo</v-icon>
              </div>
            </div>
          </div>
          <p class="black--text font-15 mt-3">
            {{ itemID.header[$root.language] }}
          </p>
          <p class="gray--text font-14">
            {{ itemID.body[$root.language] }}
          </p>
        </div>
        <GmapMap
          v-if="itemID.location && itemID.location.lat && itemID.location.long"
          :center="{
            lat: Number(itemID.location.lat),
            lng: Number(itemID.location.long)
          }"
          :zoom="15"
          style="width: 100%; height: 200px"
        >
          <GmapMarker
            key="map"
            :position="{
              lat: Number(itemID.location.lat),
              lng: Number(itemID.location.long)
            }"
          />
        </GmapMap>
        <div
          v-else
          style="
            width: 100%;
            height: 200px;
            border: 1px solid gray;
            border-radius: 5px;
          "
        >
          <p class="font-16 text-center red--text">Markers are missing</p>
        </div>
      </div>

      <div class="mt-3">
        <span class="d-block gray--text font-15 mb-3">NEED TO KNOW</span>
        <div
          v-for="(detail, i) in details"
          :key="i"
          class="d-flex align-center justify-space-between mb-2"
        >
        <div>
          <span class="font-14 black-grey--text opacity-9">{{
            detail.title
          }}</span>
          <span class="font-14 black--text font-500 ml-5">{{
            detail.value || "-"
          }}</span>
        </div>
      </div>
        <div
          class="d-flex align-center justify-space-between mb-2 mt-10"
          v-if="itemID.price != 'false'"
        >
          <span class="font-15 black--text opacity-7">PRICE PER NIGHT</span>
          <span class="font-15 black--text">{{ itemID.price }}</span>
        </div>
      </div>

      <div
        class="photos-section b-bottom pb-4 mt-4"
        v-if="itemID.media && itemID.media.length > 0"
      >
        <span class="font-15 black--text opacity-8 d-block mb-3"
          >Activities ({{ itemID.media.length }})</span
        >
        <v-row>
          <v-col cols="6">
            <div class="photo mb-2">
              <img :src="itemID.media[0].url" alt="" />
            </div>
          </v-col>

          <v-col cols="6" v-if="itemID.media.length > 1">
            <div class="photo mb-2">
              <div
                v-if="itemID.media.length > 2"
                class="photo-count cursor-pointer"
                @click="photosDialogHandler"
              >
                <span class="white--text font-26"
                  >{{ itemID.media.length - 2 }}+</span
                >
              </div>
              <img :src="itemID.media[1].url" alt="" />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog
      v-model="photosDialog"
      fullscreen
      hide-overlay
      transition="scale-transition"
      content-class="full-dialog"
    >
      <dialog-photos
        :images="images"
        @close="photosDialog = false"
      ></dialog-photos>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
    <v-dialog v-model="editAttractionDialog" max-width="750px">
      <edit-attraction
        :key="editAttractionDialog"
        :paramID="itemID._id"
        @close="closeEditAttraction"
        @reloadData="$emit('reloadData')"
      ></edit-attraction>
    </v-dialog>
    <v-dialog v-model="editCompanionValuesDialog" max-width="750px">
      <edit-attraction-companion
        :key="editCompanionValuesDialog"
        :item="itemID"
        @close="closeEditCompanionValuesDialog"
        @reloadData="$emit('reloadData')"
      ></edit-attraction-companion>
    </v-dialog>
  </div>
  <div v-else style="height: 100vh" class="pt-5">
    <v-skeleton-loader type="card"></v-skeleton-loader>
    <v-col cols="12" md="12" v-for="(item, i) in [1, 2, 3, 4, 5, 6]" :key="i">
      <v-skeleton-loader type="text"></v-skeleton-loader>
    </v-col>
  </div>
</template>

<script>
import DialogPhotos from "@/components/dialogs/Photos";
import EditAttraction from "@/components/dialogs/Attraction/Index.vue";
import EditAttractionCompanion from "@/components/dialogs/Attraction/Companion.vue";

export default {
  props: {
    itemID: {
      type: Object,
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => ["editCompanion", "delete", "edit"]
    }
  },
  components: {
    DialogPhotos,
    EditAttraction,
    EditAttractionCompanion
  },
  data: () => ({
    photosDialog: false,
    deleteDialog: false,
    editAttractionDialog: false,
    editCompanionValuesDialog: false,
    images: [],

    miniguidesDelete: { Miniguides: "delete" },
    miniguidesEdit: { Miniguides: "edit" },
    deleteEnable: false,
    editEnable: false
  }),
  computed: {
    details() {
      return [
        { title: "Distance from BEY", value: this.itemID.distance },
        {
          title: "Email",
          value: this.itemID.contact ? this.itemID.contact.email : ""
        },
        {
          title: "Phone",
          value: this.itemID.contact ? this.itemID.contact.phone : ""
        },
        {
          title: "Website",
          value: this.itemID.contact ? this.itemID.contact.website : ""
        },
        {
          title: "Facebook",
          value: this.itemID.social ? this.itemID.social.facebook : ""
        },
        {
          title: "Instagram",
          value: this.itemID.social ? this.itemID.social.instagram : ""
        },
        {
          title: "Twitter",
          value: this.itemID.social ? this.itemID.social.twitter : ""
        }
      ];
    }
  },
  methods: {
    editCompanionValues() {
      this.editCompanionValuesDialog = true;
    },
    closeEditCompanionValuesDialog() {
      this.editCompanionValuesDialog = false;
    },
    editAttraction() {
      this.editAttractionDialog = true;
    },
    closeEditAttraction() {
      this.editAttractionDialog = false;
    },
    photosDialogHandler() {
      this.images = this.itemID.media;
      this.photosDialog = true;
    },
    deleteConfirm() {
      this.deleteDialog = true;
    },
    async deleteHandler() {
      const res = await this.$axios.delete(`/miniguide/${this.itemID._id}`);

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        this.$emit("reloadData");
        this.$emit("collapse");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    collapse() {
      this.$emit("collapse");
    }
  },
  async created() {
    this.deleteEnable = await this.$store.dispatch(
      "checkPermission",
      this.miniguidesDelete
    );
    this.editEnable = await this.$store.dispatch(
      "checkPermission",
      this.miniguidesEdit
    );
  }
};
</script>

<style lang="scss">
.attractions-body {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .attractions-body-content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    .main-item {
      .main-img {
        border: 1px solid #eaedf3;
        height: 300px;
        border-radius: 5px;
        overflow: hidden;
        img {
          overflow: hidden;
        }
      }
    }
  }
}
</style>
